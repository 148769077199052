$browser-context: 16;

// grid gutter
$offset_one_side: 15px;
// if adaptive
$offset_one_side-tablet: 10px;

// fonts
$f-heading: "Jost", arial, sans-serif;
$f-text: "Roboto", arial, sans-serif;

// colors main
$c-primary: #fc3b50;
$c-secondary: #00a4fa;
$c-main-bg: #f9fafb;
$c-main-bg-white: #ffffff;
$c-secondary-light: #e6f6ff;
$c-search: #ffebed;
$slider-bullet: #fc3b50;
$icon-fill: url(#my-cool-gradient);
$icon-fill-w: #ffffff;
$icon-fill-main: #fc3b50;
$gradient: linear-gradient(90deg, rgba(131, 58, 180, 1) 0%, rgba(253, 29, 29, 1) 50%, rgba(252, 176, 69, 1) 100%);

// colors text
$c-main: #2F3045;
$c-main-lighten: #777888;
$c-text-gray: #9da5ae;
$c-text-underline-gray: #9da5ae;
$c-secondary-gray: #6d7986;

// colors helpers
$c-primary-hover: #c1050e;
$c-primary-active: #af0008;
$c-primary-disabled: #fff5f5;
$c-primary-light: #fbe6e7;
$c-dropdown: #eeeef2;
$c-accardion: #F4F2EF;
$c-success: #29b9a3;
$c-process: #f5b91f;
$c-failure: #f75566;
$c-white: #fff;
$c-gray-light: #a4b3be;

// forms
$c-inputs-bg: #f7f9fa;
$c-inputs-border: #e7e9eb;

// checkbox
$c-checkbox-border: #a4b3be;
$c-checkbox-disabled: #ced2d7;

//quiz
$c-quiz-gray:#E4DEDE;

// outline
$outline-shadow: 3px 2px 22px -6px rgba(0, 0, 0, 0.75);

// transitions
$transition-primary: 0.3s;
$transition-secondary: 0.2s;

$imgBase: "/assets/img/";
$imgBg: "/assets/img/backgrounds";

$small: 320px;
$middle: 560px;
$tablet: 760px;
$laptop: 960px;
$desktop: 1140px;
$wide: 1340px;
$large: 1800px;

// breakpoints
$xs: 420px;
$sm: 580px;
$smd: 790px;
$md: 1000px;
$lg: 1200px;
$extra-lg: 1440px;
$xlg: 1925px;
