.overlay {
  position: fixed;
  z-index: 199;
  top: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba($c-main, .6);
  opacity: 0;
  visibility: hidden;
  transition: all .4s ease;
  &.active {
    opacity: 1;
    visibility: visible;
  }
}