.header__nav-line {
  
  .text{
    display: none;
  }
  .homeIcon{
    display: block;
    li{
      padding-right: rem(40);
    }
    a{
      padding-top: rem(16);
    }
    .icon{
      font-size: 20px;
    }
  }
  @include media($laptop) {
    position: absolute;
    left: -100%;
    top: 0;
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow: auto;
    width: 300px;
    background-color: #fff;
    z-index: 500;
    padding: 20px;
    transition: all .4s ease;
    &.show {
      .col{
        &.d-flex{
          display: block;
        }
      }
      left: 0;
      .text{
        display: block;
      }
      .homeIcon{
        display: none;
      }
    }
  }
  nav {
    @include media($laptop) {
      width: 100%;
    }
    > ul {
      display: flex;
      @include media($laptop) {
        flex-direction: column;
      }
    }

    li {
      padding-left: 0;
      margin-bottom: 0;
      span{
        color: $c-main;
      }
      a{
        &.active{
          color: $c-primary;
        }
      }
      &.active {
        span {
          color: $c-primary;
        }
        .icon {
          color: $c-primary;
        }
      }

      &:not(:last-child) {
        padding-right: rem(40);
        @include media(1250px) {
          padding-right: rem(20);
        }
        @include media($laptop) {
          padding-right: 0;
        }
      }

      &::before {
        display: none;
      }
    }

    a, span {
      display: inline-block;
      font-weight: 500;
      text-decoration: none;
      padding: rem(15) 0;
    }

    .icon--menu-toggle {
      &.active {
        transform: rotate(180deg);
      }
    }

    span {
      transition: color 0.3s ease;

      &:hover {
        color: $c-primary;
      }
    }

    .submenu_wrapp {
      @include media($laptop) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
      }
      > a, span {
        padding-bottom: 20px;
        flex-grow: 1;
      }

      &:hover {
        @include media-min($laptop){
          .icon {
            transform: translate(0,2px) rotate(180deg);
            color: $c-primary;
          }
          .submenu {
            transform: translateY(-10px);
            opacity: 1;
            visibility: visible;
          }
        }
      }

      .icon {
        font-size: 13px;
        opacity: .7;
        transform: translate(0px, 2px);
        transition: all .3s ease;
        @include media($laptop){
          display: none;
        }
      }
    }

    .icon--plus {
      position: relative;
      display: none;
      width: 40px;
      height: 40px;
      @include media($laptop) {
        display: inline-block;
      }
      &::before, &::after {
        content: '';
        position: absolute;
        left: 50%;
        top: 50%;
        margin-top: -1px;
        margin-left: -5px;
        background-color: $c-main;
        width: 10px;
        height: 2px;
        transition: all .5s ease;
      }
      &:after {
        transform: rotate(90deg);
      }
      &.active {
        &::before {
          transform: rotate(360deg);
          background-color: $c-primary;
        }
        &:after {
          transform: rotate(450deg);
          background-color: $c-primary;
        }
      }
    }

    .submenu {
      position: absolute;
      width: max-content;
      min-width: rem(200);
      background-color: #fff;
      padding: rem(10);
      box-shadow: 0 0 15px 0 rgba($c-main, .1);
      border-radius: rem(10);
      @include media-min($laptop){
        display: block!important;
        overflow: hidden;
        transform: translateY(-20px);
        opacity: 0;
        visibility: hidden;
        transition: all .2s ease-in-out;
      }
      @include media($laptop) {
        position: static;
        transform: translateY(-10px);
        opacity: 1;
        visibility: visible;
        box-shadow: none;
        display: none;
      }
      li {
        padding: 0;
        transition: all .5s ease;

        &:hover {
          background-color: rgba($c-main-lighten, .1);

          a, span {
            transform: translateX(5px);
            transition: transform .7s ease;

          }
        }
      }

      a, span {
        display: block;
        padding: rem(8) rem(15);
        transition: transform .2s ease;
        font-weight: normal;
      }
    }
  }
}

.menu__header {
  display: none;
  @include media($laptop) {
    display: flex;
    justify-content: space-between;
  }
}

.menu__close button{
  position: relative;
  width: 40px;
  height: 40px;
  margin-left: 20px;
  transform: translateY(-12px);
  &:active {
    &::before, &::after{
      transform: rotate(0);
    }
  }
  &::before, &::after {
    content: '';
    position: absolute;
    right: 0;
    top: 50%;
    margin-top: -1px;
    margin-left: -15px;
    width: 30px;
    height: 2px;
    display: inline-block;
    transition: all .3s ease;
    background-color: $c-main;
  }
  &::before{
    transform: rotate(45deg);
  }
  &::after{
    transform: rotate(-45deg);
  }
}

.menu--wrapper {
  align-items: center;
  justify-content: space-between;
  height: 100%;
  flex-wrap: nowrap;
  @include media($laptop) {
    flex-grow: 1;
    flex-direction: column;
    align-items: flex-start;
    nav {
      margin-bottom: rem(40);
    }
  }
}