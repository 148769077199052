.header {
  background-color: $c-main-bg;
  position: sticky;
  top: -77px;
  left: 0;
  width: 100%;
  z-index: 200;
  &__wrapper {
    max-width: rem(1920);
    margin: 0 auto;
    padding: 0 rem(50);
    @include media($middle) {
      padding: 0 rem(15);
    }
  }
  @include media($laptop) {
    top: 0;
  }
}

.header__top-line {
  padding: rem(15) 0;
  border-bottom: 1px solid rgba($c-main-lighten, .1);
}

.header__logo {
  a {
    display: flex;
  }
  img {
    @include media($middle) {
        max-width: 120px;
    }
  }
}

.mobile__logo {
  margin-bottom: rem(20);
  @include media-min($laptop) {
    display: none;
  }
}

.header__info {
  display: flex;
  align-items: center;
}


.header__info--address {
  @include media(1300px) {
    .info__text {
      display: none;
    }
  }
  @include media($laptop) {
    display: none;
  }
}

.header__info--open-hours {
  @include media($laptop) {
    display: none;
  }
}

.info__icons {
  .icon {
    font-size: 25px;
    margin-right: rem(15);
  }
  @include media($laptop) {
    display: none;
  }
}

.info__text {
  p, a {
    font-size: rem(16);
    font-weight: 500;
    margin-bottom: 0;
    line-height: 1.5;
    text-decoration: none;
    color: $c-main;
  }
}

.header__info--phones {
  a {
    @include media(380px) {
      font-size: 11px;
    }
  }
}

.header__actions {
  display: flex;
  justify-content: flex-end;
  @include media($laptop) {
    margin-top: auto;
    flex-grow: 0;
  }
}

.btn--search {
  width: rem(45);
  min-width: rem(45);
  height: rem(45);
  margin-right: rem(25);
  border-radius: 50%;
  position: relative;
  padding: 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  // background-color: transparent;

  &::after {
    display: none;
  }

  &:hover {
    &::before {
      opacity: .2;
    }
  }

  &::before {
    content: '';
    z-index: 0;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: $c-search;
    opacity: .1;
    transition: all .3s ease;
  }

  .icon {
    position: relative;
    z-index: 2;
    font-size: rem(16);
  }
}
.btn--phone {
  .icon {
    display: none;
  }
  @include media(1200px) {
    width: rem(45);
    min-width: rem(45);
    height: rem(45);
    background: transparent;
    &::before {
      content: '';
      z-index: 0;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: $gradient;
      opacity: .1;
      transition: all .3s ease;
    }
    &::after {
      display: none;
    }

    &:hover {
      &::before {
        opacity: .2;
      }
    }
    .icon {
      display: inline-block;
      color: $c-main;
      position: relative;
      z-index: 2;
      font-size: rem(16);
    }
    span {
      display: none;
    }
  }
}
