.faqPage{
    .accordion{
        .accordion__item{
            background: $c-main-bg;
            padding: rem(30) rem(60) ;
            position: relative;
            
            .accordion__text{
                border: 0;
                color:$c-main-lighten;
                font-weight: 400;
                padding: 0;
                margin-top: rem(40);
                @include media($xs){
                    font-size: rem(14);
                }
            }
            .accordion__title{
                color:$c-main;
                padding-right: rem(80);
                font-size: rem(20);    
                &:after,&:before{
                   display: none;
                }
                .accordion__circle{
                    position: absolute;
                    height: 50px;
                    width: 50px;
                    background: $c-primary;
                    right: 0;
                    top: 50%;
                    transform: translate(0%,-50%);
                    z-index: 1;
                    border-radius: 50%;
                    &:after,&:before{
                        content: '';
                        background: $c-white;
                        z-index: 2;
                        position: absolute;
                        width: rem(15);
                        height: rem(3);
                        right: 50%;
                        top: 50%;
                        margin-top: rem(-1.5);
                        background-color: $c-white;
                        transition: all .4s ease;
                    }
                    &:before {
                        transform:translate(50%,50%) rotate(360deg);
                    }
                
                    &:after {
                        transform:translate(50%,50%) rotate(450deg);
                    }
                    @include media($xs){
                        height: 36px;
                        width: 36px;
                    }
                }
                &.active {
                    .accordion__circle{
                        &:before,&:after {
                            transform:translate(50%,50%) rotate(0);
                        }
                    }
                }
                @include media($xs){
                    padding-right: rem(60);
                    font-size: rem(14);
                }
            }
            @include media($xs){
                padding: rem(20);
            }
        }
    }
}