.form {
  legend {
    font-family: $f-heading;
    font-weight: bold;
    font-size: rem(40);
    line-height: 1.2;
    color: $c-main;
    margin-bottom: rem(20);
  }

  .inputs {
    margin-bottom: rem(25);

    input, textarea {
      width: 100%;
      display: block;
      border: none;
      outline: none;
      height: rem(54);
      border-radius: 30px;
      padding: rem(24) rem(20) rem(24) rem(40);
      margin-bottom: rem(8);

      &::placeholder {
        color: $c-main-lighten;
        transition: all .3s ease;
      }

      &:focus {
        box-shadow: inset 0 0 3px 0 rgba($c-main, .1);

        &::placeholder {
          opacity: 0;
        }
      }
    }

    textarea {
      height: rem(130);
      resize: none;
    }
  }

  .form__politic {
    padding: rem(5) 0 rem(30);

    p {
      color: $c-main;
    }
  }

  .btn {
    width: 100%;
    height: rem(55);
  }
}
.form-input--h{
  display: none !important;
}
.politic-link{
  color: $c-main-lighten;
}