.section__inner {
  position: relative;
}

.video-block {
  position: absolute;
  width: 50%;
  left: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
  @include desktop() {
    position: relative;
    width: 100%;
    aspect-ratio: 16 /9;
    margin-bottom: rem(40);
  }
  @include small() {
    height: 300px;
  }
}

.btn--play-button {
  position: relative;
  width: 200px;
  height: 200px;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &:hover {
    .play-icon {
      .icon {
        animation: pulsate-bck 0.5s ease-in-out both;
      }
    }
  }

  .button-border {
    position: absolute;
    left: 0;
    top: 0;
    transform: scale(0.5);

    &-1 {
      animation: pulse 2s 0.3s cubic-bezier(0.390, 0.575, 0.565, 1.000) both infinite;
    }

    &-2 {
      animation: pulse 2s 1.5s cubic-bezier(0.390, 0.575, 0.565, 1.000) both infinite;
    }
  }

  .play-icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 100px;
    background: #fff;
    border-radius: 50%;
  }

  .icon {
    font-size: 40px;
    overflow: hidden;
    position: relative;
  }
}


@keyframes pulse {
  0% {
    transform: scale(0.6);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}

@keyframes pulsate-bck {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}