.dotted-slider {
  .swiper-pagination {
    position: relative;
    height: rem(30);
    display: flex;
    align-items: flex-end;
    justify-content: center;
    bottom: 0;
  }

  .swiper-pagination-bullet {
    width: rem(15);
    height: rem(15);
    background: #545561;
    opacity: 1;
    transition: all .3s ease;

    &:hover {
      background: $slider-bullet;
    }

    &.swiper-pagination-bullet-active {
      background: $slider-bullet;
    }
  }
}

.right-slider {
  position: relative;
  width: calc(50% + (1140px / 12 * 6) - 15px);
  right: 0;
  left: auto;

  @include desktop() {
    width: calc(50% + (960px / 12 * 6) - 15px);
  }

  @include laptop() {
    width: calc(50% + (760px / 12 * 6) - 15px);
  }

  @include tablet() {
    width: 100%;
  }

  .swiper-slide {
    img {
      width: 100%;
    }
  }

  .swiper-button {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 2;
    width: rem(30);
    height: rem(30);
    right: auto;
    left: rem(13);
    top: rem(13);
    border-radius: 50%;
    background: $c-primary;
    margin: 0;

    &-next {
      left: rem(47);
      transform: rotate(180deg);
    }
    &-nextt {
      left: rem(47);
      transform: rotate(180deg);
    }

    &-disabled {
      opacity: .7;
    }

    &::after {
      display: none;
    }

    .icon {
      color: #fff;
      font-size: rem(14);
    }
  }
}

.material-slider {
  .swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto;
    border-right: 2px solid #e4dede;
    padding: 0 rem(20);
    @include media(420px) {
      border: none;
    }
  }
}

.client-slider {
  .swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto;
  }
}

.news-slider {
  .swiper-wrapper {
    padding-top: rem(10);
  }
}

.productionSlider{
  padding-bottom: 30px;
}
.productionSlider__content{
  display: flex;
  flex-wrap: wrap;
  height: 95%;
}
.productionSlider__imgBlock{
  position: relative;
  &::before{
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: brightness(0.5) blur(2px);
    opacity: 0;
    visibility: hidden;
    transition: 0.3s;
  }
}
.productionSlider__item{
  flex: 0 0 25%;
  padding: 15px;
  max-height: 285px;
  min-height: 285px;
  img{
    display: flex;
  }
  .masonry__content{
    position: absolute;
    left: 0;
    bottom: 0;
    .masonry__hidden{
      transition: 0.3s;
    }
  }
  .productionSlider__imgBlock{
    width: 100%;
    height: 100%;
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .play{
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 100px;
      height: 100px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: $c-white;
      .icon{
        color: $c-primary;
        font-size: 40px;
      }
    }
    .loop{
      position: absolute;
      right: 30px;
      top: 30px;
      width: 30px;
      height: 30px;
      background: $c-white;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0;
      visibility: hidden;
      transition: 0.3s;
      .icon{
        font-size: 14px;
        color: #000;
      }
      @include media(420px){
        
          right: 10px;
          top: 10px;
          width: 25px;
          height: 25px;
        
      }
    }
  }
  &:hover{
    .productionSlider__imgBlock{
      backdrop-filter: brightness(0.5) blur(2px);
      &::before{
        opacity: 1;
        visibility: visible;
      }
      .loop{
        opacity: 1;
        visibility: visible;
      }
    }
    
    .masonry__hidden{
      height: 100%;
      max-height: 270px;
    }
  }
  &:last-child{
    flex: 0 0 50%;
  }
  @include media(1200px){
    max-height: 240px;
    min-height: 240px;
    .masonry__content{
      padding: rem(10);
      .masonry__title{
        font-size: rem(17);
      }
    }
  }
  @include media(992px){
    max-height: 190px;
    min-height: 190px;
  }
  @include media(720px){
    flex: 0 0 50%;
    max-height: 285px;
    min-height: auto;
    &:last-child{
      flex: 0 0 100%;
    }
  }
  @include media(420px){
    .masonry__content{
      
      .masonry__title{
        font-size: rem(17);

      }
      .masonry__intro{
        font-size: rem(14);
      }
    }
  }
  @include media(350px){
    flex: 0 0 100%;
  }
}


.portfolio-popup__height, .portfolio-poe{
  .loop{
    position: absolute;
    right: 30px;
    top: 30px;
    z-index: 2;
    width: 30px;
    height: 30px;
    background: $c-white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    visibility: hidden;
    transition: 0.3s;
    .icon{
      font-size: 14px;
      color: #000;
    }
    @include media(420px){
      
        right: 10px;
        top: 10px;
        width: 25px;
        height: 25px;
      
    }
  }
  &:hover{
    .loop{
      opacity: 1;
      visibility: visible;
    }
  }
}









.productionSlider__arrow{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .swiper-button{
    position: static;
    background: $c-primary;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    &::after{
      display: none;
    }
    .icon{
      font-size: 14px;
      color: $c-white;
    }
    &-next{
      transform: rotate(180deg);
      margin-left: 10px;
    }
  }
}
.productionS{
  @include media(550px){
    .col-10{
      flex: 0 0 100%;
      max-width: 100%;
    }
    .col-2{
      flex: 0 0 100%;
      max-width: 100%;
      justify-content: flex-start;
      margin-top: 20px;
    }
  }
}
.painting{
  @include media(1200px){
    .col-6{
      flex: 0 0 100%;
      max-width: 100%;
      &:first-child{
        margin-bottom: 30px;
      }
    }
  }
}
.painting__content{
  display: flex;
  flex-wrap: wrap;
  ul{
   flex: 0 0 50%;
   padding-right: 30px;
   &:last-child{
    padding-right: 0;
   }
   @include media(420px){
    padding-right: 16px;
   }
   @include media(370px){
    padding-right: 0;
    flex: 0 0 100%;
   }
  }
}
.painting__right{
  background: #F9FAFB;
  padding: 30px;
  ul{
    li{
      position: relative;
      padding-left: 18px;
      .icon{
        position: absolute;
        left: 0;
        top: 8px;
        font-size: 10px;
        color: #FC3B50;
      }
    }
  }
}

.fancybox-thumbnails-arrow {
  display: none !important;
}