.image-bg {
  position: relative;
  padding: rem(40) rem(40) rem(40) 0;
  background: $c-main-bg;
  &--fullHeight{
    height: 100%;
    max-height: 620px;
    img{
      height: 100%;
    }
  }
}