p {
  font-size: rem(16);
  margin-bottom: 1rem;
  margin-top: 0;
  color: $c-main-lighten;
  letter-spacing: -0.16px;
}

p:last-child {
  margin-bottom: 0;
}

ul {
  margin: 0;
  padding: 0;
}

ul li {
  list-style-type: none;
  position: relative;
  padding-left: 18px;
  color: $c-main-lighten;
  font-size: rem(16);
  line-height: 1.6;
  margin-bottom: rem(10);
}
.content {
  ul li::before {
    content: "";
    position: absolute;
    left: 0;
    top: rem(7);
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: $c-primary;
  }
}



a {
  color: $c-main;
  transition: color 0.3s ease;
  display: inline-block;
}

a:hover {
  color: $c-primary;
  text-decoration: none;
}

.high__title {
  color: $c-primary;
  margin-bottom: rem(15);
  line-height: 2;
  letter-spacing: -0.16px;
  text-transform: uppercase;
}

h2, .h2 {
  font-size: rem(30);
  line-height: 1.2;
  font-weight: 900;
  margin: 0 0 rem(30) 0;
  letter-spacing: -0.4px;
  @include phone() {
    font-size: rem(30);
  }
}

ol {
  padding-left: rem(20);
  margin-bottom: rem(30);
}

ol li {
  margin-bottom: rem(10);
}
