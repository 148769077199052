.quiz{
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 10000000;
    top: 0;
    left: 0; 
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: auto;
    opacity: 0;
    visibility: hidden;
    transition:all 0.3s ease;
    &.active{
        opacity: 1;
        visibility: visible;
    }
}
body{
    &.overflow-hidden{
        overflow: hidden;
        position: relative;
        &:before{
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            background: rgba(0,0,0,0.5);
            z-index: 1000;
        }
    }
}
.quiz__body{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    vertical-align: middle;
    width: 100%;
    min-height: 100vh;
}
.quiz__content{
    background: $c-white;
    max-width: 1350px;
    position: relative;
    display: flex;
    min-height: 745px;
    margin-right: 30px;
    margin-left: 30px;
    .quiz__close{
        position: absolute;
        right: 20px;
        top: 20px;
        font-size: rem(20);
    }
    @include tablet {
        margin-right: 15px;
        margin-left: 15px;
    }
    @include media($md){
        .quiz__close{
            right: 10px;
            top: 10px;
            font-size: rem(16);
        }
    }
}
.quiz__bg{
    flex: 0  0 30%;
    position: relative;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    @include media($smd){
        display: none;
    }
}
.quiz__verticalSteps{
    position: absolute;
    height: 100%;
    right: 0;
    top: 0;
    width: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    &:before{
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 20%;
        background: $c-primary;
    }
}
.quiz__verticalSteps-content{
    min-height: 60%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}
.quiz_verticalLine{
    width: 4px;
    flex-grow: 1;
    height: calc(100% - 40px);
}
.quiz__step{
   flex-grow: 1;
   display: flex;
   flex-direction: column;
   align-items: center;
    span{
        display: block;
        width: 40px;
        height: 40px;
        background: $c-white;
        border:4px solid $c-quiz-gray;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        font-size: rem(16);
        color: $c-quiz-gray;
        font-weight: 700;
        line-height: 1.5; 
    }
    &.active{
        span{
            border-color:$c-primary;
            color: $c-primary;
        }
        .quiz_verticalLine{
            background: $c-primary;
        }
    }
}

.quiz__main{
    flex: 0  0 70%;
    padding: rem(50);
    background: url('/assets/img/assets/quiz_content_bg.png') center / cover no-repeat;
    .quiz__title{
        font-size: rem(40);
        line-height: 1.2;
    }
    @include media($md){
        padding: rem(30);
        .quiz__title{
            font-size: rem(30);
        }
    }
    @include media($smd){
        flex: 0 0 100%;
    }
}
.quiz__header{
    .quiz__title{
        font-size: rem(30);  
    }
    .quiz__text{
        font-size: rem(24);
        font-family: $f-heading;
        color:$c-quiz-gray;
        line-height: 1.5;
    }
    @include media($md){
        .quiz__title{
            font-size: rem(24);
        }
        .quiz__text{
            font-size: rem(18);
        }
    }
}

.quiz__progress{
    display: flex;
    align-items: center;
}
.quiz__line{
    display: flex;
    width: 100%;
    max-width: 765px;
    position: relative;
    overflow: hidden;
    height: 14px;
    border-radius: 6px;
    background:$c-quiz-gray;
}
.quiz__line-element{
    height: 100%;
    flex-grow: 1;
    &.active{
        background:$c-primary; 
    }
    &.last{
        border-radius: 2px 25px 25px 2px;
    }
}
.quiz__wrap{
    display: none;
    &.active{
        display: flex;
        flex-wrap: wrap;
    }
}
.quiz__subtitle{
    font-size: rem(17);
    font-family:$f-heading;
    color: $c-main;
    line-height: 1.176;
}
.quiz__element{
    flex: 0 0 calc(25% - 35px);
    max-width: 150px;
    margin-right: rem(35);
    margin-bottom: rem(35);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: relative;
    transition: $transition-primary;
    &.gradient-border-block{
        background: $c-quiz-gray;
        transition: $transition-primary;
        &:hover{
            transition: $transition-primary;
            background: $gradient;
            .icon{
                transition: $transition-primary;
                color:$c-primary;
            }
        }
        &.active{
            background: $gradient;
        }
        .icon{
           margin-bottom: 0;
        }
    }
    .icon{
        font-size: rem(60);
        color: $c-quiz-gray;
    }
    .quiz__text{
        margin-top: rem(15);
        font-size: rem(17);
        font-family: $f-heading;
        line-height: 1.176;  
    }
    .quiz__fake-input{
        position: absolute;
        right: 10px;
        top: 10px;
    }
    &.active{
        border-color: $c-primary;
        .icon{
            color:$c-primary;
        }
    }
    @include media(1260px){
        flex: 0 0 calc(50% - 35px);
        max-width: 50%;
    }
    @include media($xs){
        flex: 0 0 100%;
        max-width: 100%;
        margin-right: 0;
    }
}
.gradient-border-block__content{
    padding: rem(15);
    padding-top: rem(26);
    .info{
        margin-left: rem(15);
        .title{
            text-align: left;
        }
    }
}
.quiz_button_block_send{
    display: none;
}
.quiz__fake-input{
    width: 25px;
    height: 25px;
    min-width: 25px;
    min-height: 25px;
    max-width: 25px;
    max-height: 25px;
    border-radius: 50%;
    border:2px solid $c-quiz-gray;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $c-white;
    span{
        display: block;
        width: 80%;
        height: 80%;
        border-radius: 50%;
        background: $c-primary;
        transition: $transition-primary;
        opacity: 0;
        visibility: hidden;
    }
}
.quiz__input{
    display: none;
    &:checked + .quiz__fake-input{
        span{
            opacity: 1;
            visibility: visible; 
        }
    }
}
.quiz__radioElement{
    .quiz__text{
        margin-left: 10px;
        font-size: rem(17);
        font-family: $f-heading;
        line-height: 1.176; 
        display: flex;
        align-items: center;
        &--inline{
            display: inline;
        }
    }
    &--halfWidth{
        flex:0 0 calc(50% - 15px);
        a{
            display: inline;
        }
        @include media($sm){
            flex: 0 0 100%;
        }
    }
}
.quiz__button{
    min-height: 54px;
    &:disabled{
        cursor: default;
    }
    &.quiz__button-prev{
        margin-right: rem(20);
        &:disabled{
            display: none;
        }
    }
    &.quiz__button-next{
        &:disabled{
            background: $c-white;
            color:$c-quiz-gray !important;
            border:2px solid $c-quiz-gray; 
            &:after{
                background: $c-white;
            }
        }
       
    }
    @include media($smd){
        min-height: 40px;
    }
}
.quiz_button_block_step{
    display: flex;
}
.quiz__button-block{
    @include media($xs){
       // display: flex;
        flex-direction: column-reverse;
        align-items: start;
        
        .quiz__button{
            margin-right: 0;
            margin-bottom: rem(20);
        }
    }
}
.quiz__blockElement{
    flex: 0 0 50%;
    display: flex;
    flex-wrap: wrap;
    .quiz__textField{
        max-width: 300px;
    } 
    @include media(1260px){
        flex: 0 0 100%;
    }
}
.quiz__textField{
    border: 2px solid $c-quiz-gray;
    border-radius: 30px;
    padding: rem(15) rem(25);
    color:$c-main;
    flex: 0 0 calc(50% - 15px);
    margin-right: rem(15);
    &::placeholder{
        color:$c-main; 
    }
    &--endStep{
        @include media($sm){
            flex: 0 0 100%;
            margin-right: 0;
        }
    }
}
.quiz__textField-block{
    flex: 0 0 calc(50% - 17px);
    margin-right: rem(15);
    @include media($sm){
        flex: 0 0 100%;
        margin-right: 0;
    }
    input{
        margin-right: 0;
        width: 100%;    
    }
}
.quiz__number{
    .d-flex{
        align-items: start;
    }
    .quiz__title{
        font-size: rem(17);
        line-height: 1.176;
    }
    .quiz__textField{
        padding: rem(10) rem(20);
        text-align: center;
        flex: 1 1 auto;
        min-height: 54px;
        -moz-appearance: textfield;
        &::-webkit-outer-spin-button, &::-webkit-inner-spin-button{
            -webkit-appearance: none;
            margin: 0;
        }
        &:hover, &:focus{
            -moz-appearance: number-input;
        }
        @include media($sm){
            padding: rem(10);
            min-height: 50px;
        }
    }
}
.quiz__dimensions{
    flex: 0 0 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 20%;
    div{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .quiz__title{
        margin-top: 20px;
    }
    .quiz__title, .quiz__subtitle{
        font-size: rem(17);
        font-family: $f-heading;
        line-height: 1.176;   
    }
    .quiz__subtitle{
        color:$c-primary;
    }
    img{
        width: 220px;
        height: 170px;
        object-fit: cover;
        object-position: center;
    }
    @include media(1260px){
       flex-direction: row;
       justify-content: start;
       margin-left: 0;
       flex: 0 0 100%;
       //align-items: start;
       margin-top: rem(40);
       margin-bottom: rem(20);
       text-align: center;
       div{
        margin-left: rem(20);
        align-items: start;
        text-align: left;
        .quiz__title{
            margin-top: 0;
        }
       }
    }
    @include media(500px){
        img{
            width: 150px;
            height: 100px;
            object-fit: contain;
        }
    }
}
.quiz__fileBlock{
    display: flex;
    flex-wrap: wrap;
    .quiz__text, .quiz__linkFile{
        font-family: $f-heading;
        font-size: rem(17);
        line-height: 1.176;
        margin-right: rem(16);    
    }
    @include media($xs){
        flex-direction: column;
        .quiz__linkFile{
            margin-top: rem(20);
            margin-bottom: rem(20);
        }
    }
}
.quiz__file{
    display: none;
}
.quiz__fileName{
    cursor: pointer;
    max-width: 50%;
    .icon{
        font-size: rem(10);
        display: none;
        &.active{
            display: inline;
        }
    }
}

