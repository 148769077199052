.icons-block--left {
  display: inline-flex;

  .icon {
    font-size: rem(52);
    margin-right: rem(18);
  }

  .title {
    font-family: $f-heading;
    font-size: rem(18);
    font-weight: bold;
    line-height: 1.4;
    color: $c-main;
    margin-bottom: rem(10);
    letter-spacing: -0.1px;
  }
}

.icons__text{
  max-width: 190px;
}