.faq {
  position: relative;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100% calc(100% - 140px);
  @include laptop(){
    background-size: cover;
    padding: rem(40) 0;
  }

  &::before {
    content: '';
    position: absolute;
    left: 0;
    width: 100%;
    top: 70px;
    height: calc(100% - 140px);
    background-color: rgba(#1d1e2b, .8);
    z-index: 0;
    @include laptop(){
      height: 100%;
      top: 0;
    }
  }

  .wrapper {
    position: relative;
    z-index: 2;
  }

  .form {
    padding: rem(45) rem(70);
    background-color: $c-main-bg;
    @include desktop() {
      padding: rem(40) rem(25);
    }
    @include laptop() {
      margin-bottom: rem(30);
    }
  }
}