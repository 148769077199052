.steps {
  .image-bg {
    @include desktop() {
      margin-top: rem(30);
    }
    img {
      width: 100%;
    }
    .btn {
      position: absolute;
      bottom: rem(30);
      left: 50%;
      transform: translateX(-50%);
      padding: rem(13) rem(50);
    }
  }
}
.steps__list{
  &--allWidth{
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    li{
      flex: 0 0 calc(50% - 60px);
      margin-right: 60px;
      @include media($md){
        flex: 0 0 100%;
        margin-right: 0;
      }
    }
  }
}