.footer-form {
  position: relative;
  z-index: 3;
  margin-bottom: rem(-80);

  .form__inner {
    padding: rem(60) rem(15);
    background-repeat: no-repeat;
    background-size: cover;

    h2, .h2 {
      color: #fff;
      font-size: rem(30);
      margin-bottom: 0;
      @include laptop {
        margin-bottom: rem(20);
        text-align: center;
      }
    }
  }

  form {
    position: relative;
  }

  fieldset {
    margin: 0;
  }

  input {
    width: 100%;
    height: rem(54);
    border-radius: 50px;
    border: none;
    padding: rem(10) rem(180) rem(10) rem(20);
  }

  .btn {
    position: absolute;
    top: 2px;
    right: 3px;
    height: rem(50);
  }
  .content{
    *{
      color:$c-white;
    }
    @include media($md){
      margin-bottom: 40px;
      text-align: center;
    }
  }
  .politic{
    color:$c-white;
    font-weight: 400;
    a{
      color:$c-white;
    }
  }
}
