.modal--portfolio {
  .modal__body {
    max-width: 1140px;
  }
}

.portfolio-inner__page {
  .portfolio__body {
    padding: 0;
  }
}

.modal--portfolio {
  .portfolio__body, .portfolio__footer {
    @include media($md) {
      padding-left: rem(35);
      padding-right: rem(35);
    }
  }
}

.portfolio__body {
  padding: 60px rem(65) 0;
}

.portfolio__header {
  padding-bottom: rem(30);
}

.portfolio__title {
  font-size: rem(35);
  font-weight: bold;
  line-height: 1.2;
  margin: 0 0 rem(20) 0;
  @include media($sm) {
    font-size: rem(28);
  }
}

.portfolio__slider {
  @include col(7);
  @include media($smd) {
    @include col(12);
    margin-bottom: rem(20);
  }
}

.portfolio-slider {
  aspect-ratio: 4/3;
  margin-bottom: rem(20);

  img {
    height: 100%;
    width: 100%;
    max-width: 100%;
    object-fit: cover;
  }

  .swiper-button-prev:after, .swiper-button-next:after {
    color: $c-primary;
    font-size: 29px;
  }
}

.portfolio-slider--thumb {
  .swiper-slide {
    opacity: 0.5;
    aspect-ratio: 4/3;

    &.swiper-slide-thumb-active {
      opacity: 1;
    }

    img {
      height: 100%;
      width: 100%;
      max-width: 100%;
      object-fit: cover;
    }
  }
}

.portfolio__project-details {
  @include col(5);
  @include media($smd) {
    @include col(12);
  }
}

.portfolio__table {
  background-color: $c-main-bg;
  padding: rem(20) rem(25);

  table {
    width: 100%;
  }

  td {
    width: 50%;
    padding: rem(5) 0;
    line-height: 1.2;

    &:nth-child(odd) {
      color: $c-primary;
    }
    &:nth-child(even) {
      color: $c-main-lighten;
    }
  }
}

.portfolio__title--small {
  margin: 0 0 rem(10) 0;
  font-size: rem(24);
  line-height: 1.2;
  letter-spacing: -0.5px;
}

.portfolio-related-slider {
  padding-bottom: rem(45);

  .slider__content {
    display: block;
    background-size: cover;
    background-position: center;

    &:hover {
      .portfolio-related__more {
        letter-spacing: 1px;
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      display: flex;
    }
  }
}

.portfolio-related__more {
  position: absolute;
  left: 0;
  bottom: 0;
  color: #fff;
  font-family: $f-heading;
  font-size: rem(20);
  letter-spacing: -0.1px;
  line-height: 1.2;
  font-weight: 900;
  transition: all .3s ease;
  background: linear-gradient(0deg, rgba($c-main,1) 0%, rgba(#fff,0) 100%);
  padding: rem(30);
  width: 100%;
}

.portfolio__footer {
  background: $c-main-bg;
  padding: rem(60) rem(65);
 
}

.footer__title {
  font-family: $f-heading;
  color: $c-main;
  margin-bottom: rem(40);
  font-size: rem(20);
  letter-spacing: -0.1px;
  font-weight: 900;
  line-height: 1.2;
}

.footer-col {
  @include media($lg) {
    @include col(12);
    margin-bottom: rem(30);
  }
}

.footer-content {
  display: flex;
  @include media(500px) {
    flex-direction: column;
  }
}

.footer-content__title {
  color: $c-primary;
  font-weight: normal;
  font-size: rem(16);
  margin-right: rem(6);
  @include media($lg) {
    min-width: 150px;
  }
  @include media(500px) {
    max-width: none;
    margin-bottom: rem(10);
  }
}

.footer-content__text {
  p, a {
    color: $c-main-lighten;
    margin-bottom: 0;
    line-height: 1.4;
  }

  a {
    text-decoration: none;

    &:hover {
      color: $c-primary;
    }
  }
}