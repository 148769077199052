.advantages-block {
  .advantages__img-col {
    position: relative;
    @include col(5);
    .video-block{
      width: 100%;
      max-width: 475px;
      height: 472px;
      @include media($xs) {
        height: 422px;
      }
    }
    @include media($lg) {
      @include col(12);
      display: flex;
      margin-bottom: 70px;
      justify-content: center;
    }
    @include media($sm) {
      padding-right: 60px;
    }
    &--about{
      @include media($sm) {
        padding-right: 10px;
        padding-left: 10px;
      }
    }
  }
  .advantages__list {
    @include col(7);
    @include shift-left(1);
    @include media($lg) {
      @include col(12);
    }
    &--about{
      // @include col(7);
      @include shift-right(1);
      padding-left: 0;
      @include media($lg) {
        @include col(12);
        @include shift-left(1);
      }
      @include media($sm) {
        padding-right: 10px;
        padding-left: 10px;
      }
    }
  }
  .gradient-border-block {
    width: rem(270);
    height: rem(250);
    position: absolute;
    bottom: -50px;
    right: -50px;
    &--about{
      right: 0;
      left: -50px;
      @include media($sm) {
        left: 0px;
      }
    }
    &--mini{
      width: rem(220);
      height: rem(250);
    }
  }
  .advantages__img {
    position: relative;
    img {
      line-height: 0;
      display: block;
    }
  }
  @include media($lg){
    padding-bottom: 0;
  }
}
.advantages-block-video{
  .gradient-border-block{
    width: rem(360);
    height: rem(525);
  }
}
.advantages__row{
  display: flex;
  @include media($xs) {
    flex-direction: column-reverse;
  }
}
.advantages__info{
  width: rem(210);
  height: rem(250);
  margin-right: rem(30);
  background: $c-primary;
  .gradient-border-block__content{
    *{
      color:$c-main-bg-white;
    }
  }
}
.advantages__items{
  display: flex;
  flex-direction: column;
  margin-top: rem(30);
  margin-bottom: rem(30);
  span{
    font-size: rem(20);
    font-family: $f-heading;
    color: $c-main;
    line-height: 1.3;
    &.active{
      color:$c-primary;
    }
  }
}

