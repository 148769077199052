.footer {
  position: relative;
  padding-top: rem(160);
  background-color: $c-main;
  color: #fff;

  h3 {
    margin: 0 0 rem(24) 0;
    font-family: $f-heading;
    font-size: rem(22);
    line-height: 1.2;
    letter-spacing: -0.1px;
    font-weight: bold;
    @include phone() {}
    margin-bottom: rem(15);
  }

  li {
    padding: 0;

    &::before {
      display: none;
    }

    a {
      color: #fff;
      text-decoration: none;

      &:hover {
        color: $c-primary;
      }
    }

    span {
      color: #fff;
    }

    &.active {
      a, span {
        color: $c-primary;
      }
    }
  }

  .list__wrapper {
    width: 40%;
    @include media($md) {
      @include col(6);
      margin-top: rem(30);
      &:first-of-type{
        padding-left: 0;
      }
    }
    @include small() {
      width: 100%;
      @include col(12);
      padding-left: 0 !important;
      ul {
        column-count: 2;
      }
      &:last-of-type{
        margin-bottom: rem(30);
      }
    }
  }
}


.footer__info {
  @include small() {
    margin-bottom: rem(30);
  }
  a {
    margin-bottom: rem(20);
  }

  p {
    color: #fefefe;
    line-height: 1.5;
  }
}

.footer__contacts {
  li {
    display: flex;
  }

  .icon {
    flex-shrink: 0;
    font-size: rem(28);
    margin-right: rem(20);
  }

  p, a, span {
    color: #fff;
  }
}

.footer__copy {
  padding: rem(20) 0;
  border-top: 2px solid rgba(#fff, .1);

  p {
    text-align: center;
    color: #fff;

    strong {
      font-weight: normal;
      color: $c-primary;
    }
  }
}