.services-slider {
  .swiper-slide {
    height: auto;
  }

  .slider__content {
    padding: rem(30) rem(25);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
    background-color: $c-main-bg;
    transition: all .5s ease;

    &:hover {
      background: $c-main;

      .title, .text {
        color: #fff;
      }

      .link {
        color: #fff;
        letter-spacing: 1.2px;

        &:hover {
          color: $c-primary;
        }
      }
    }

    .icon {
      font-size: rem(72);
      margin-bottom: rem(15);
    }

    .title {
      font-family: $f-heading;
      font-weight: 900;
      font-size: rem(22);
      line-height: 1.2;
      letter-spacing: -0.1px;
      margin: 0 0 rem(10) 0;
      transition: all .5s ease;
    }

    .text {
      margin-bottom: rem(25);
      transition: all .5s ease;
    }

    .link {
      color: $c-main;
      letter-spacing: -0.1px;
      text-decoration: none;
      margin-top: auto;
      transition: all .5s ease;
    }
  }
}