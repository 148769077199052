.job{
    width: 100%;
    background: $c-main-bg;
    padding: rem(60);
    .job__content{
        display: flex;
        @include media($md){
            flex-direction: column;
        }
    }
    .job__item{
        &:nth-child(2){
            margin: 0 rem(80);
        }
        .btn{
            @include media($md){
                margin-top: 20px;
            }
        }
        @include media($lg){
            &:nth-child(2){
                margin: 0 rem(50);
            }
        }
        @include media($md){
            &:nth-child(2){
                margin: 0;
            }
        }
    }
    .job__info{
        display: flex;
        span{
            display: block;
            margin-bottom: rem(15);
        }
        @include media($xs){
            flex-direction: column;
            margin-bottom: 20px;
            span{
                margin-bottom: rem(6);
                
            }
        }
    }
    .job__title{
        margin-right: rem(40);
        min-width: rem(100);
        color:$c-main;
        font-weight: 500;
        @include media($md){
            margin-right: rem(20);
        }
    }
    .job__text{
        color:$c-main-lighten;
    }
    @include media($lg){
        padding: rem(30);
    }
}