.masonry__item {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  overflow: hidden;
}

.masonry__inner {
  position: relative;
  display: flex;
  height: 100%;
  align-items: flex-end;
  text-decoration: none;
  .img{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
  &:hover {
    color: inherit;
    transition: all .3s ease;
    backdrop-filter: brightness(0.5) blur(2px) ;
    .masonry__hidden {
      max-height: 280px;
      transition: all .6s ease-out;
    }
    .masonry__more {
      letter-spacing: 1.2px;
    }
  }
}
.portfolio-popup__height{
  min-height: 330px;
  height: fit-content;
  @include media(1600px){
    min-height: 300px;
  }
  @include media($wide){
    min-height: 260px;
  }
  @include media($desktop){
    min-height: 230px;
  }
  @include media($laptop){
    min-height: 180px;
  }
  @include media($tablet){
    min-height: 180px;
  }
  @include media($middle){
    min-height: 180px;
  }
}

.masonry__item--portfolio-link {
  background: $c-primary;
  .masonry__inner {
    &:hover {
      backdrop-filter: none;
      .masonry__more {
        letter-spacing: 1px;
        &:hover {
          color: #fff;
        }
      }
    }
  }
  .masonry__content {
    background: none;
  }
  .masonry__hidden {
    max-height: 100%;
    //position: absolute;
  }
}

.masonry__content {
  position: relative;
  padding: rem(20);
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-end;
  background: linear-gradient(0deg, rgba($c-main,1) 0%, rgba(#fff,0) 100%);
}

.masonry__title {
  font-family: $f-heading;
  color: #fff;
  font-weight: 900;
  font-size: rem(20);
  line-height: 1.2;
  margin: 0;
}

.masonry__hidden {
  max-height: 0;
  height: 100%;
  overflow: hidden;
  transition: all .2s ease-out;

}

.masonry__intro {
  color: #fff;
  margin: rem(10) 0;
  font-size: 14px;
  line-height: 1.4;
  letter-spacing: -0.1px;
  max-height: 100px;
  overflow: hidden;
}

.masonry__more {
  font-size: rem(14);
  font-weight: bold;
  color: #fff;
  transition: all .3s ease;
  transition-delay: .2s;
  &:hover {
    color: $c-primary;
    transition-delay: 0s;
  }
}

