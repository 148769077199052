.news{
  .h2{
    a{
      text-decoration: none;
    }
  }
}
.news__item {
  letter-spacing: -0.1px;
  text-decoration: none;
  transition: all .3s ease;

  &:hover {
    transform: translateY(-10px);

    h3 {
      color: $c-primary;
    }
  }

  img {
    margin-bottom: rem(10);
  }

  time {
    color: $c-main-lighten;
    font-weight: bold;
    font-size: rem(16);
    margin-bottom: rem(10);
    display: inline-block;
  }

  h3 {
    font-family: $f-heading;
    font-size: rem(25);
    line-height: 1.2;
    font-weight: bold;
    color: $c-main;
    margin: 0;
    transition: all .3s ease;
  }
}

.news-img{
  @include col(5);

  @include media($smd) {
    @include col(12);
    margin-bottom: rem(20);
  }
}

.news-info{
  @include col(7);

  @include media($smd) {
    @include col(12);
  }
}

.news-title{
  margin-top: 0;
  line-height: 100%;
  max-width: 500px;
}

.news-wrap{
  display: flex;
  flex-wrap: wrap;
  @include media(747px) {
    justify-content: center;
  }

}

.news-block{
  max-width: 350px;
  padding: 20px 0;
  margin-right: rem(30);
  &:nth-child(3n){
    margin-right: 0;
  }
  @include media(1200px) {
    &:nth-child(3n){
      margin-right: rem(30);
    }
    &:nth-child(2n){
      margin-right: 0;
    }
  }
  @include media(747px) {
    margin-right: rem(30);

    &:nth-child(3n){
      margin-right: rem(30);
    }
    &:nth-child(2n){
      margin-right: rem(30);
    }
  }
  
}

.news-page-title{
  display: flex;
  justify-content: center;
}