.price{
    width: 100%;
    overflow: auto;
    //max-width: 100%;
    @include media(540px) {
        max-width: 540px;
    }
}
.price__header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: $gradient;
    //max-width: 320px;
    span{
        display: block;
        font-size: rem(17);
        color: $c-main-bg-white;
        line-height: 1;
        width: 25%;
        font-family: $f-heading;
        margin: rem(20);
        min-width: 140px;
        @include media($md) {
            margin: rem(10);
            //ont-size: rem(15);
        }
    }
}
.price__content{
    width: 100%;
}
.price__line{
    display: flex;
    align-items: center;
    justify-content: space-between;
    &:nth-child(even){
        background: $c-main-bg;
    }
    span{
        display: block;
        width: 25%;
        margin: rem(20);
        min-width: 140px;
        font-size: rem(16);
        color: $c-main-lighten;
        line-height: 1.5;   
    }
}
.note{
    *{
        color:$c-main;  
    }
}
.tabprise{
    min-width: 590px;
}
.tabprise__header{
    background: $gradient !important;
    span{
        font-size: rem(17);
        color: $c-main-bg-white;
        line-height: 1;
        font-family: $f-heading;
    }
    @include media($xs){
        span{
            font-size: 12px;
        }
    }
}
.tabprise__contentr{
    span{
        font-size: rem(16);
        color: $c-main-lighten;
        line-height: 1.5;
    }
    @include media($xs){
        span{
            font-size: 10px;
        }
    }
}
td{
    font-size: rem(16);
    color: $c-main-lighten;
    line-height: 1.5;
    padding: rem(20);
    @include media($xs){
        font-size: 14px;  
    }
}