.menu__open {
  display: none;
  align-items: center;
  margin-left: 20px;
  @include media($laptop) {
    display: flex;
  }
  @include media($middle) {
    margin-right: rem(15);
  }
  @include media(380px) {
    margin-left: rem(15);
  }
  button{
    position: relative;
    width: 30px;
    height: 30px;
    display: inline-block;
    padding: 0;
    overflow: hidden;
    &:hover {
      span {
        background-color: $c-primary;
        &::before, &::after {
          background-color: $c-primary;
        }
      }
    }
    &.active {
      span {
        transform: rotate(45deg);
        &::after {
          transform: rotate(-90deg);
          top: 0px;
        }
        &::before {
          top: -200px;
        }
      }
    }
  }
  span {
    position: absolute;
    top: 50%;
    margin-top: -1px;
    left: 0;
    display: inline-block;
    width: 30px;
    height: 2px;
    background-color: $c-main;
    transition: all .3s ease;
    &::before, &::after {
      content: '';
      position: absolute;
      left: 0;
      width: 30px;
      height: 2px;
      display: inline-block;
      transition: all .3s ease;
      background-color: $c-main;
    }
    &::before {
      top: -7px;
    }
    &::after {
      top: 7px;
    }
  }
}