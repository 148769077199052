.color-about{
    .col{
        img{
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center;
            max-width: 540px;
            max-height: 415px;
        }
    }
    @include media($smd){
        .col{
            flex: 0 0 100%;
            max-width: 100%;
            margin-bottom: rem(40);
        }
    }
}
.color__wrapper{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}
.color-catalog__item{
    flex: 0 0 calc(25% - 70px);
    margin-right: 70px;
    text-decoration: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    a{
        text-decoration: none;
        transition: $transition-primary;
        &:hover{
            color: $c-primary;
        }
    }
    img{
        object-fit: cover;
        object-position: center;
        //max-width: 100px;
        max-height: 275px;
    }
    @include media($smd){
        flex: 0 0 calc(50% - 70px);
    }
    @include media($xs){
        flex: 0 0 100%;
        margin-right: 0;
    }
}
.color-variants__item{
    flex: 0 0 30%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    &--center{
        flex: 0 0 calc(40% - 80px);
        display: flex;
        margin-right: 40px;
        margin-left: 40px;
        justify-content: center;
        img{
            
            object-fit: contain;
            object-position: center;
        }
    }
    &:last-child{
        .color-variants__option{
            flex-direction: row-reverse;
            justify-content: flex-end;
        }
        .color-variants__desc{
            text-align: left;
            margin-right: 0;
            margin-left: rem(20);
        }
    }
    @include media($md){
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        flex-direction: row;
        justify-content: start;
        flex: 0 0 100%;
        margin: 0;
        &--center{
            order: 3;
            flex: 0 0 100%;
            justify-content: start;
        }
        .color-variants__option{
            flex-direction: column-reverse;
            flex: 0 0 calc(33% - 40px);
            margin-right: 40px;
            align-items: start;
        }
        .color-variants__desc{
            text-align: left;
            margin: 0;
        }
        &:last-child{
            .color-variants__option{
                flex-direction: column-reverse;
                flex: 0 0 calc(33% - 40px);
                margin-right: 40px;
                align-items: start;
            }
            .color-variants__desc{
                text-align: left;
                margin: 0;
            }  
        }
    }
    @include media($smd){
        .color-variants__option{
            flex: 0 0 100%;
            margin: 0;
            flex-direction: row-reverse;
            justify-content: flex-end;
            margin-bottom: rem(40);
        }
        .color-variants__desc{
            margin-left: rem(20);
        }
        &:last-child{
            .color-variants__option{
                flex: 0 0 100%;
                margin: 0;
                flex-direction: row-reverse;
                justify-content: flex-end;
                margin-bottom: rem(40);
            }
            .color-variants__desc{
                margin-left: rem(20);
            } 
        }
    }
}
.color-variants__option{
    display: flex;
    //align-items: center;
    margin-bottom: rem(30);
    height: 180px;
    @include media($md){
        height: auto;
    }
}
.color-variants__desc{
    text-align: right;
    margin-right: 20px;
}
.color-variants__imgBlock{
    img{
        width: 100px;
        height: 100px;
        min-height: 100px;
        min-width: 100px;
        border-radius: 50%;
        object-fit: cover;
        object-position: center;
    }
    @include media($md){
        img{
            width: 80px !important;
            height: 80px !important;
            min-height: 80px !important;
            min-width: 80px !important;
        }
    }
}