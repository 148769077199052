.main-slider {
  padding: rem(80) 0 rem(150);
  background-color: $c-main-bg;
  background-image: url($imgBg + '/main-slider-bg.jpg');
  background-position: center right;
  background-repeat: no-repeat;
  background-size: 60% 100%;
  position: relative;
  .bg{
    position: absolute;
    right: 0;
    top: 0;
    width: 60%;
    height: 100%;
    background-size: contain;
    img{
      width: 100%;
      height: 100%;
      
    }
    @include tablet() {
      display: none;
    }
   
  }
  @include laptop {
    padding: rem(120) 0 rem(80);
  }
  @include tablet() {
    padding: 0 0 rem(120);
    background-image: none;
    background-color: transparent;
    .wrapper {
      padding: 0 !important;
    }
    .row-flex {
      margin: 0 !important;
    }
  }

  .wrapper {
    position: relative;
  }

  .swiper-button {
    right: auto;
    top: auto;
    bottom: -80px;
    left: 15px;
    width: 40px;

    &::after {
      display: none;
    }

    .icon {
      font-size: 40px;
    }
    @include media($smd){
      bottom: -60px;
    }
  }

  .swiper-button-next {
    left: 70px;
    @include tablet() {
      left: 14%;
      margin-left: 10px;
    }
    @include media($xs) {
      left: 18%;

    }
  }

  .swiper-button-prev {
    transform: rotate(180deg);
    @include tablet() {
      left: 14%;
      margin-left: -50px;
    }
    @include media($xs) {
      left: 18%;
     
    }
  }
}

.row__content-slider {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  transform: translateY(-50%);
  z-index: 1;
  @include tablet() {
    position: relative;
    width: 100%;
    transform: translateX(0);
  }
}

.image-slider {
  @include col(7);
  aspect-ratio: 4 / 3;
  @include laptop {
    @include col(5);
  }
  @include tablet() {
    @include col(12);
    aspect-ratio: 16 / 9;
    padding: 0 !important;
  }
  .swiper {
    height: 100%;
  }

  .swiper-slide {
    width: 100%;
    height: 100%;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.content-slider {
  
  @include col(6);
  @include shift-left(6);

  @include laptop {
    @include col(8);
    @include shift-left(4);
  }
  @include tablet() {
    @include col(12);
    @include shift-left(0);
    padding: 0 !important;
  }

  box-sizing: content-box;

  .swiper {
    height: 100%;
  }
  
}

.slider-content {
  display: flex;
  background-color: #fff;
  height: 100%;
  @include media($smd){
    background: $c-main-bg;
  }
}

.slider-content__inner {
  display: flex;
  flex-direction: column;
  padding: rem(50);
  @include phone() {
    padding: rem(30) rem(15);
  }
}

.slider__small-title {
  text-transform: uppercase;
  margin-bottom: rem(25);
}

.slider__big-title {
  font-family: $f-heading;
  font-weight: 900;
  font-size: rem(40);
  margin-bottom: rem(40);
  color: $c-main;
  line-height: .9;
  @include desktop {
    font-size: rem(30);
    margin-bottom: rem(30);
  }
}

.slider__text {
  color: $c-main-lighten;
  margin-bottom: rem(30);
  @include desktop {
    margin-bottom: rem(16);
  }
}

.slider__buttons {
  display: flex;
  margin-top: auto;

  .btn:not(:last-child) {
    margin-right: rem(10);
  }
}