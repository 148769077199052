
// .select{
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     padding: rem(20);
//     padding-right: rem(50);
//     margin-left: rem(20);
//     border: 2px solid $c-quiz-gray;
//     border-radius: 30px;
//     position: relative;
//     transition: $transition-primary;
//     background: $c-white;
//     input{
//         display: none;
//     }
//     &::after{
//         content: '';
//         position: absolute;
//         width: 10px;
//         height: 15px;
//         background: url('/assets/img/svg/next.svg') center / cover no-repeat;
//         right: 20px;
//         top: 50%;
//         transform:translate(0,-50%) rotate(90deg);
//     }
//     .select-label{
//         opacity: 0;
//         visibility: hidden;
//         height: 0;
//         order: 1;
//         &.active{
//             height: 100%;
//             opacity: 1;
//             visibility: visible;
//             order: 0;
//         }
    
//     }
//     &.active{
//         .select-label{
//             height: 100%;
//             opacity: 1;
//             visibility: visible;
//         }
//     }
//     &--empty{
//         padding-left: 42px;
//         padding-right: 42px;
//         &:after{
//             display: none;
//         }
//     }
//     @include media($xs){
//         margin-left: 0;
//         &::after{
//             right: 12px;
//             width: 8px;
//             height: 12px; 
//         }
//     }
// }

.select {
	position: relative;
	width: 108px;
    position: relative;
    transition: 0.3s;
    background: #fff;
    &.is-active{
        .select__body{
            opacity: 1;
            visibility: visible;
        }
        .select__header{
            border-radius: 30px 30px 0 0;
            .icon{
                transform: translate(-50%, -50%) rotate(-90deg);
            }
        }
        //border-radius: 30px 30px 0 0;
        //border-bottom: 0;
    }
}


.select__header {
	display: flex;
    min-height: 54px;
    justify-content: center;
    padding: rem(10) rem(20);
    border: 2px solid #E4DEDE;
    border-radius: 30px;
    transition: $transition-primary;
    &.select__header-s{
        cursor: pointer;
    }
    .icon{
        position: absolute;
        font-size: 14px;
        left: 74%;
        top: 50%;
        transform: translate(-50%, -50%) rotate(90deg);
        transition: $transition-primary;
    }
    @include media($sm){
        padding: rem(10);
        min-height: 50px;
    }
   
}
.select__current {
    display: flex;
    align-items: center;
}
.select__icon {
	align-items: center;
	display: flex;
	flex-shrink: 0;
	justify-content: center;
	height: 40px;
	margin-left: auto;
	text-align: center;
	width: 40px;
}

.select__body {
    background: #fff;
    z-index: 9999;
	opacity: 0;
    visibility: hidden;
	position: absolute;
    border: 2px solid #E4DEDE;
    border-radius: 30px;
    min-height: 54px;
    border-radius: 0 0 30px 30px;
    border-top: 0;
    transition: $transition-primary;
    width: 100%;
}
.select__item {
	cursor: pointer;
    padding: rem(10) rem(20);
    width: 100%;
    display: flex;
    span{
        
    }
    input{
        display: none;
        position: absolute;
    }
}
