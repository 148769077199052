.lists__wrapper {
  padding: rem(15);
  padding-right: 0;
  @include desktop() {
    padding: 0;
  }
}

.icon-title-list {
  display: flex;

  &:not(:last-child) {
    // margin-bottom: rem(60);
    margin-bottom: 18px;
  }

  h3 {
    margin: 0 0 rem(15) 0;
    font-family: $f-heading;
    color: $c-main;
    font-size: rem(22);
    line-height: 1.2;
    letter-spacing: -0.1px;
  }
}

.list__icon {
  .icon {
    font-size: rem(55);
    margin-right: rem(10);
  }
}