.products {
  position: relative;
  background-size: 100% 55%;
  background-repeat: no-repeat;
  background-position: center top;
  @include phone() {
    background-size: 100% calc(100% - 3.55rem);
  }

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 55%;
    background: $gradient;
    opacity: 0.9;
    z-index: 1;
    @include phone() {
      height: calc(100% - 3.55rem);
    }
  }

  .wrapper {
    position: relative;
    z-index: 2;
  }

  .products__header {
    margin-bottom: rem(34);
  }

  .high__title {
    color: #fff;
  }

  h2, .h2 {
    color: #fff;
  }

  .products__link {
    color: #fff;
    @include phone() {
      margin-top: rem(20);
    }
  }
}