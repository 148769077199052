.page-header {
  position: relative;
  z-index: 2;
  padding: rem(60) 0;
  background-size: cover;
  background-repeat: no-repeat;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: $gradient;
    z-index: -1;
    opacity: .6;
  }
  .page-header__title {
    font-family: $f-heading;
    font-size: rem(40);
    line-height: 1.1;
    color: #fff;
    margin: 0 0 rem(25) 0;
    @include media($smd) {
      font-size: rem(50);
    }
    @include media($xs) {
      font-size: rem(35);
    }
  }
}