.masonry__wrapper {
  display: grid;
  grid-gap: 20px;
  @include media(600px) {
    display: block;
    .masonry__item {
      height: 300px;
      margin-bottom: rem(20);
    }
  }

  &--1 {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 320px;
  }

  &--2 {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 320px;
  }

  &--3 {
    grid-template-columns: 1fr 2fr 1fr;
    grid-template-rows: 320px;
    @include media($lg) {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

  &--4 {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 300px 300px;

    .masonry__item {
      &:nth-child(1) {
        grid-column: 1/3;
        grid-row: 1/2;
      }

      &:nth-child(2) {
        grid-column: 3/5;
        grid-row: 1/2;
      }

      &:nth-child(3) {
        grid-column: 1/3;
        grid-row: 2/3;
        @include media($lg) {
          grid-column: 1/3;
        }
      }

      &:nth-child(4) {
        grid-column: 3/5;
        grid-row: 2/3;
        @include media($lg) {
          grid-column: 3/5;
        }
      }
    }
  }

  &--5 {
    grid-template-columns: repeat(24, 1fr);
    grid-template-rows: 300px 300px;
    @include media($md) {
      grid-template-rows: 300px 300px 300px;
    }

    .masonry__item {
      &:nth-child(1) {
        grid-column: 1/10;
        grid-row: 1/2;
        @include media($md) {
          grid-column: 1/12;
        }
      }

      &:nth-child(2) {
        grid-column: 10/16;
        grid-row: 1/2;
        @include media($md) {
          grid-column: 12/25;
        }
      }

      &:nth-child(3) {
        grid-column: 16/25;
        grid-row: 1/3;
        @include media($md) {
          grid-column: 1/16;
          grid-row: 2/3;
        }
      }

      &:nth-child(4) {
        grid-column: 1/8;
        grid-row: 2/3;
        @include media($md) {
          grid-column: 16/25;
        }
      }

      &:nth-child(5) {
        grid-column: 8/16;
        grid-row: 2/3;
        @include media($md) {
          grid-column: 1/25;
          grid-row: 3/4;
        }
      }
    }
  }

  &--6 {
    grid-template-columns: repeat(24, 1fr);
    grid-template-rows: 300px 300px 300px;

    .masonry__item {
      &:nth-child(1) {
        grid-column: 1/10;
        grid-row: 1/2;
        @include media($lg) {
          grid-column: 1/12;
        }
      }

      &:nth-child(2) {
        grid-column: 10/16;
        grid-row: 1/2;
        @include media($lg) {
          grid-column: 12/25;
        }
      }

      &:nth-child(3) {
        grid-column: 16/25;
        grid-row: 1/3;
        @include media($lg) {
          grid-column: 1/14;
          grid-row: 2/3;
        }
      }

      &:nth-child(4) {
        grid-column: 1/8;
        grid-row: 2/3;
        @include media($lg) {
          grid-column: 14/25;
        }
      }

      &:nth-child(5) {
        grid-column: 8/16;
        grid-row: 2/3;
        @include media($lg) {
          grid-column: 1/11;
          grid-row: 3/4;
        }
      }

      &:nth-child(6) {
        grid-column: 1/8;
        grid-row: 3/4;
        @include media($lg) {
          grid-column: 11/25;
          grid-row: 3/4;
        }
      }
    }
  }

  &--7 {
    grid-template-columns: repeat(24, 1fr);
    grid-template-rows: 300px 300px 300px;
    @include media($lg) {
      grid-template-rows: 300px 300px 300px 300px;
    }

    .masonry__item {
      &:nth-child(1) {
        grid-column: 1/10;
        grid-row: 1/2;
        @include media($lg) {
          grid-column: 1/12;
        }
      }

      &:nth-child(2) {
        grid-column: 10/16;
        grid-row: 1/2;
        @include media($lg) {
          grid-column: 12/25;
          grid-row: 1/3;
        }
      }

      &:nth-child(3) {
        grid-column: 16/25;
        grid-row: 1/3;
        @include media($lg) {
          grid-column: 1/12;
          grid-row: 2/3;
        }
      }

      &:nth-child(4) {
        grid-column: 1/8;
        grid-row: 2/3;
        @include media($lg) {
          grid-column: 1/25;
          grid-row: 3/4;
        }
      }

      &:nth-child(5) {
        grid-column: 8/16;
        grid-row: 2/3;
        @include media($lg) {
          grid-column: 1/9;
          grid-row: 4/5;
        }
      }

      &:nth-child(6) {
        grid-column: 1/12;
        grid-row: 3/4;
        @include media($lg) {
          grid-column: 9/17;
          grid-row: 4/5;
        }
      }

      &:nth-child(7) {
        grid-column: 12/25;
        grid-row: 3/4;
        @include media($lg) {
          grid-column: 17/25;
          grid-row: 4/5;
        }
      }
    }
  }

  &--8 {
    grid-template-columns: repeat(24, 1fr);
    grid-template-rows: 300px 300px 300px;
    @include media($lg) {
      grid-template-rows: 300px 300px 300px 300px 300px;
    }

    .masonry__item {
      &:nth-child(1) {
        grid-column: 1/10;
        grid-row: 1/2;
        @include media($lg) {
          grid-column: 1/13;
          grid-row: 1/2;
        }
      }

      &:nth-child(2) {
        grid-column: 10/16;
        grid-row: 1/2;
        @include media($lg) {
          grid-column: 13/25;
          grid-row: 1/2;
        }
      }

      &:nth-child(3) {
        grid-column: 16/25;
        grid-row: 1/2;
        @include media($lg) {
          grid-column: 1/12;
          grid-row: 2/4;
        }
      }

      &:nth-child(4) {
        grid-column: 1/12;
        grid-row: 2/3;
        @include media($lg) {
          grid-column: 12/25;
          grid-row: 2/3;
        }
      }

      &:nth-child(5) {
        grid-column: 12/25;
        grid-row: 2/3;
        @include media($lg) {
          grid-column: 12/25;
          grid-row: 3/4;
        }
      }

      &:nth-child(6) {
        grid-column: 1/8;
        grid-row: 3/4;
        @include media($lg) {
          grid-column: 1/25;
          grid-row: 4/5;
        }
      }

      &:nth-child(7) {
        grid-column: 8/16;
        grid-row: 3/4;
        @include media($lg) {
          grid-column: 1/14;
          grid-row: 5/6;
        }
      }

      &:nth-child(8) {
        grid-column: 16/25;
        grid-row: 3/4;
        @include media($lg) {
          grid-column: 14/25;
          grid-row: 5/6;
        }
      }
    }
  }

  &--9 {
    grid-template-columns: repeat(24, 1fr);
    grid-template-rows: 300px 300px 300px;
    @include media($lg) {
      grid-template-rows: 300px 300px 300px 300px 300px;
    }
    @include media($smd) {
      grid-template-rows: 300px 300px 300px 300px 300px 300px;
    }
    .masonry__item {
      &:nth-child(1) {
        grid-column: 1/10;
        grid-row: 1/2;
        @include media($lg) {
          grid-column: 1/12;
        }
      }

      &:nth-child(2) {
        grid-column: 10/16;
        grid-row: 1/2;
        @include media($lg) {
          grid-column: 12/25;
          grid-row: 1/3;
        }
      }

      &:nth-child(3) {
        grid-column: 16/25;
        grid-row: 1/2;
        @include media($lg) {
          grid-column: 1/12;
          grid-row: 2/4;
        }
      }

      &:nth-child(4) {
        grid-column: 1/12;
        grid-row: 2/3;
        @include media($lg) {
          grid-column: 12/25;
          grid-row: 3/4;
        }
      }

      &:nth-child(5) {
        grid-column: 12/18;
        grid-row: 2/3;
        @include media($lg) {
          grid-column: 1/16;
          grid-row: 4/5;
        }
      }

      &:nth-child(6) {
        grid-column: 18/25;
        grid-row: 2/3;
        @include media($lg) {
          grid-column: 16/25;
          grid-row: 4/5;
        }
      }

      &:nth-child(7) {
        grid-column: 1/9;
        grid-row: 3/4;
        @include media($lg) {
          grid-column: 1/9;
          grid-row: 5/6;
        }
        @include media($smd) {
          grid-column: 1/14;
        }
      }

      &:nth-child(8) {
        grid-column: 9/15;
        grid-row: 3/4;
        @include media($lg) {
          grid-column: 9/17;
          grid-row: 5/6;
        }
        @include media($smd) {
          grid-column: 14/25;
        }
      }

      &:nth-child(9) {
        grid-column: 15/25;
        grid-row: 3/4;
        @include media($lg) {
          grid-column: 17/25;
          grid-row: 5/6;
        }
        @include media($smd) {
          grid-column: 1/25;
          grid-row: 6/7;
        }
      }
    }
  }

  &--10 {
    grid-template-columns: repeat(24, 1fr);
    grid-template-rows: 300px 300px 300px 300px;
    @include media($lg) {
      grid-template-rows: 300px 300px 300px 300px 300px 300px;
    }
    .masonry__item {
      &:nth-child(1) {
        grid-column: 1/10;
        grid-row: 1/2;
        @include media($lg) {
          grid-column: 1/13;
          grid-row: 1/2;
        }
      }

      &:nth-child(2) {
        grid-column: 10/16;
        grid-row: 1/2;
        @include media($lg) {
          grid-column: 13/25;
          grid-row: 1/2;
        }
      }

      &:nth-child(3) {
        grid-column: 16/25;
        grid-row: 1/3;
        @include media($lg) {
          grid-column: 1/10;
          grid-row: 2/4;
        }
      }

      &:nth-child(4) {
        grid-column: 1/8;
        grid-row: 2/3;
        @include media($lg) {
          grid-column: 10/25;
          grid-row: 2/3;
        }
      }

      &:nth-child(5) {
        grid-column: 8/16;
        grid-row: 2/3;
        @include media($lg) {
          grid-column: 10/25;
          grid-row: 3/4;
        }
      }

      &:nth-child(6) {
        grid-column: 1/10;
        grid-row: 3/5;
        @include media($lg) {
          grid-column: 1/15;
          grid-row: 4/5;
        }
      }

      &:nth-child(7) {
        grid-column: 10/17;
        grid-row: 3/4;
        @include media($lg) {
          grid-column: 15/25;
          grid-row: 4/5;
        }
      }

      &:nth-child(8) {
        grid-column: 17/25;
        grid-row: 3/4;
        @include media($lg) {
          grid-column: 1/13;
          grid-row: 5/6;
        }
      }

      &:nth-child(9) {
        grid-column: 10/18;
        grid-row: 4/5;
        @include media($lg) {
          grid-column: 1/13;
          grid-row: 6/7;
        }
      }

      &:nth-child(10) {
        grid-column: 18/25;
        grid-row: 4/5;
        @include media($lg) {
          grid-column: 13/25;
          grid-row: 5/7;
        }
      }
    }
  }
}