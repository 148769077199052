.breadcrumbs {
  ul {
    display: flex;
    justify-content: center;
  }
  li {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    line-height: 1;
  }
  a, span {
    color: #fff;
    text-decoration: none;
  }
  a {
    &:hover {
      text-decoration: underline;
    }
  }
  .separator {
    font-size: rem(20);
    padding: 0 rem(18);
  }
}