.btn {
  position: relative;
  z-index: 0;
  display: inline-block;
  min-width: rem(170);
  font-family: $f-text;
  font-weight: normal;
  font-style: normal;
  border: none;
  outline: none !important;
  user-select: none;
  transition-property: color, background;
  transition-timing-function: ease;
  transition-duration: $transition-primary;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: rem(16);
  line-height: 1.2;
  padding: rem(13) rem(20);
  text-decoration: none;
  cursor: pointer;
  box-sizing: border-box;
  border-radius: rem(50);
  overflow: hidden;
  text-align: center;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    width: 550px;
    height: 550px;
    margin: auto;
    background: $c-primary;
    border-radius: 50%;
    z-index: -1;
    transform-origin: top center;
    transform: translateX(-50%) translateY(-5%) scale(.4);
    transition: transform .6s ease;
  }

  &:hover {
    text-decoration: none;

    &::after {
      transform: translateX(-45%) translateY(0) scale(1);
      transform-origin: bottom center;
      transition: transform .9s ease;
    }
  }

  &:active {
    transform: translateY(1px);
  }

  &:focus {
    
    &::after {
      //transform: translateX(-45%) translateY(0) scale(1);
      //transform-origin: bottom center;
    }
  }

  &--clear {
    background-color: transparent;
    padding: 0;
    border: none;
    outline: none;
    color: inherit;
    border-radius: 0;
    text-decoration: none;
    cursor: pointer;
    transition-property: color, background;
    transition-timing-function: ease;
    transition-duration: $transition-primary;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    user-select: none;

    &::after {
      display: none;
    }

    &:hover {
      color: inherit;
    }
  }

  &--gradient {
    background: $gradient;
    color: $c-white !important;

    &::after {
      opacity: .6;
    }
  }

  &--gradient-border {
    background: $gradient;
    color: $c-main;
    transition: color .2s ease;
    transition-delay: .2s;

    &::before {
      content: '';
      position: absolute;
      left: 2px;
      top: 2px;
      right: 2px;
      bottom: 2px;
      background-color: #fff;
      z-index: -1;
      border-radius: 50px;
    }

    &::after {
      opacity: 1;
    }

    &:hover {
      color: #fff;
      transition-delay: 0s;
    }
  }

  &--main {
    background-color: $c-main;
    color: #fff;
  }
}
