.promotion{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    .promotion__item{
        //max-width: calc(33% - 30px);
        flex: 0 0 calc(33% - 30px);
        margin-right: rem(30);
        img{
            height: rem(240);
            width: 100%;
            object-fit: cover;
            display: flex;
        }
        @include media(745px){
            margin-right: 0;
            flex: 0 0 55%;
        }
    }
    .promotion__content{
        padding: 30px;
        background: $c-main-bg;
        h3{
            margin-top: 0;
        }
        .input-form{
            margin-bottom: rem(30);
        }
        .btn{
            height: 55px;
        }
    }
    .promotion__timer{
        display: flex;
    }
    .promotion__time{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background: $c-primary;
        min-width: 60px;
        height: 60px;
        //padding: 15px;
        margin-right: rem(10);
        span{
            color:$c-white;
        }
        @include media($xs){
            min-width: 50px;
            height: 50px;
        }
    }
    .promotion__title{
        font-size: rem(18);
        font-weight: 700;
        line-height: 1.2;    
    }
    .promotion__text{
        font-size: rem(12);
        line-height: 1.714;
          
    }
}