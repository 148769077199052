.image-item {
  display: flex;
  height: 100%;
  text-decoration: none;
  figure {
    display: flex;
    height: 100%;
    width: 100%;
    margin: 0;
    padding: rem(45) rem(25) rem(45) 0;
    background-color: $c-main-bg;
    transition: all .3s ease;

    @include desktop() {
      flex-direction: column;
      padding: 0;
    }

    &:hover {
      background-color: $c-main;

      .image-item__node {
        color: #fff;
      }

      .link {
        letter-spacing: 1.2px;
      }
    }
  }

  img, picture {
    flex-shrink: 0;
    line-height: 0;
  }

  img {
    width: 100%;
    object-fit: cover;
  }

  figcaption {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: rem(10) 0 0 rem(20);
    
    @include desktop() {
      padding: rem(20);
      flex-grow: 1;
    }
  }

  .image-item__node {
    transition: all .3s ease;
  }

  .high__title {
    color: $c-primary;
    letter-spacing: -0.16px;
    display: block;
    margin-bottom: rem(10);
  }

  .title {
    font-family: $f-heading;
    font-size: rem(20);
    font-weight: 900;
    line-height: 1;
    letter-spacing: -0.5px;
    margin: 0 0 rem(10) 0;
    text-align: left;
    color: $c-main;
  }

  .text {
    line-height: 1.5;
    letter-spacing: -0.8px;
    color: $c-main-lighten;
    margin-bottom: rem(15);
  }

  .link {
    margin-top: auto;
    line-height: 1.5;
    letter-spacing: -0.8px;
    text-decoration: none;
    color: $c-main;

    &:hover {
      color: $c-primary !important;
    }
  }
}