.search-overlay{
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 99999;
    opacity: 0;
    visibility: hidden;
    transition: all .3s ease-in-out;
    overflow: hidden;
    &.search-overlay-active{
        opacity: 1;
        visibility: visible;
        .search-overlay-layer{
            transform: translateX(0);
        }
        .search-overlay-close{
            opacity: 1;
            visibility: visible;
        }
        .search-overlay-form{
            opacity: 1;
            visibility: visible;
        }
    }
}
.d-table{
    width: 100%;
    height: 100%;
    display: table;
}
.d-table-cell{
    vertical-align: middle;
    display: table-cell;
}
.search-overlay-layer{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    transform: translateX(100%);
    &:first-child {
        left: 0;
        background-color: rgba(0,0,0,.5);
        transition: all .3s ease-in-out 0s;
    }
    &:nth-child(2) {
        left: 0;
        background-color: rgba(0,0,0,.4);
        transition: all .3s ease-in-out .3s;
    }
    &:nth-child(3) {
        left: 0;
        background-color: rgba(0,0,0,.7);
        transition: all .9s ease-in-out .6s;
    }
}
.search-overlay-close{
    position: absolute;
    top: 40px;
    right: 40px;
    width: 50px;
    z-index: 2;
    text-align: center;
    cursor: pointer;
    padding: 10px;
    transition: all .9s ease-in-out 1.5s;
    opacity: 0;
    visibility: hidden;
    &:hover{
        .search-overlay-close-line{
            transform: rotate(180deg);
            background: $c-primary;
        }
        
    }
}
.search-overlay-close-line{
    width: 100%;
    height: 3px;
    float: left;
    margin-bottom: 5px;
    background-color: #fff;
    transition: all .5s ease;
    &:first-child{
        transform: rotate(45deg);
    }
    &:nth-child(2){
        margin-top: -7px;
        transform: rotate(-45deg);
    }
    
}
.search-overlay-form{
    transition: all .9s ease-in-out 1.4s;
    opacity: 0;
    visibility: hidden;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateY(-50%) translateX(-50%);
    z-index: 2;
    max-width: 500px;
    width: 500px;
    form{
        position: relative;
    }
    button{
        display: inline-flex;
        position: absolute;
        align-items: center;
        justify-content: center;
        right: 5px;
        top: 5px;
        width: 50px;
        color: #fff;
        height: 50px;
        border-radius: 50%;
        background-color: $c-primary;
        transition: $transition-primary;
        border: none;
        font-size: 20px;
        line-height: 50px;
    }
    @include media(530px){
        max-width: 300px;
       // width: 300px;
    }
}
.input-search{
    display: block;
    width: 100%;
    height: 60px !important;
    border: none;
    border-radius: 30px;
    color: #777888;
    padding: 0 0 0 25px;
    font-weight: 500;
    outline: 0;
}