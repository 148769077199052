.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  background-color: transparent;
  overflow: auto;
  transition: transform .3s ease;
  transform: scale(0);
  display: none;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &.active {
    display: block;
    transform: scale(1);
    background-color: rgba(#000, .8);
  }
}

.modal--video {
  .modal__body {
    max-width: 1000px;
  }

  .modal__close-button {
    display: none;
  }
}

.modal__body {
  opacity: 0;
  position: relative;
  z-index: 9999999;
  width: 90%;
  max-width: 600px;
  background-color: #fff;
  margin: 120px auto 20px;

  &.active {
    animation: fade-in-top 0.6s 0.3s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  }
}

@keyframes fade-in-top {
  0% {
    transform: translateY(-50px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.modal__close-button {
  background-color: transparent;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 24px;
  right: 24px;
  padding: 0;
  border: none;
  outline: none;
  cursor: pointer;
  z-index: 5;

  &:focus {
    outline: none;
  }

  &::before, &::after {
    content: '';
    display: block;
    width: 25px;
    height: 2px;
    background-color: #555555;
    position: absolute;
    left: 0;
    top: 17px;
  }

  &::after {
    transform: rotate(45deg);
  }

  &::before {
    transform: rotate(-45deg);
  }
}