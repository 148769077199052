.equipment{
  display: grid;
  grid-template: repeat(2, 15.125rem)/repeat(3, 22rem);
  grid-gap: 1.5625rem;
  &-item{
    position: relative;
    max-width: 350px;
    max-height: 236px;
    overflow: hidden;
  }
  &-img{
    position: absolute;
    width: 100%;
    height: 100%;
  }
  &-item:hover .equipment-hidden{
    top: 0;
    left: 0;
  }
  @include media($lg) {
    grid-template: repeat(3, 15.125rem)/repeat(2, 23rem);
    justify-content: center;
  }
  @include media($tablet) {
    grid-template: repeat(6, 15.125rem)/repeat(1, 23rem);
    justify-content: center;
  }
}

.equipment-hidden {
  position: absolute;
  top: 0;
  left: 100%;
  background-color: $c-primary;
  width: 100%;
  height: 100%;
  padding: 16px 24px;
  font-size: 14px;
  transition: 0.7s;
  ul{
    li{
      font-size: 14px;
      padding-left: 0;
      margin-bottom: 0;
    }
  }
  *{
    color: $c-white;
  }
}
