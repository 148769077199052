.column-text {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: min-content 1fr;

  &:nth-of-type(odd) {
    .h2 {
      grid-column: 1/3;
      grid-row: 1/2;
      margin-bottom: rem(20);
    }

    .img {
      grid-column: 3/4;
      grid-row: 1/3;
    }

    .column-text__content:nth-of-type(even) {
      grid-column: 1/2;
      grid-row: 2/3;
      margin-right: rem(30);
    }

    .column-text__content:nth-of-type(odd) {
      grid-column: 2/3;
      grid-row: 2/3;
      margin-right: rem(30);
    }
  }

  &:nth-of-type(even) {
    .h2 {
      grid-column: 2/4;
      grid-row: 1/2;
      margin-bottom: rem(20);
    }

    .img {
      grid-column: 1/2;
      grid-row: 1/3;
      margin-right: rem(30);
    }

    .column-text__content:nth-of-type(even) {
      grid-column: 2/3;
      grid-row: 2/3;
      margin-right: rem(30);
    }

    .column-text__content:nth-of-type(odd) {
      grid-column: 3/4;
      grid-row: 2/3;
    }
  }

  &:not(&:last-of-type) {
    margin-bottom: rem(60);
  }

  @include media($smd) {
    display: block;
    .img {
      margin: 0 rem(20) rem(20) 0;
      float: left;
    }
    .column-text__content {
      margin-bottom: rem(20);
    }
  }

  .img {
    @include media($sm) {
      margin-right: 0 !important;
      float: none;
      img {
        width: 100%;
      }
    }
  }
  .column-text__content {
    @include media($sm) {
      margin-right: 0 !important;
    }
  }
}