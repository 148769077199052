.steps__list {
  li {
    display: flex;
    padding-left: 0;
    margin-bottom: rem(15);

    &::before {
      display: none;
    }

    h3 {
      font-size: rem(20);
      margin: 0 0 rem(15) 0;
      font-family: $f-heading;
      font-weight: bold;
      color: $c-main;
    }
  }

  .step-count {
    width: rem(50);
    height: rem(50);
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: rem(15);
    border-radius: 50%;
    background: $c-primary;
    color: #fff;
    font-size: rem(18);
    font-weight: bold;
  }
}