.accordion {
  width: 100%;

  .accordion__item {
    &:first-of-type {
      .accordion__text {
        display: block;
      }
    }
  }
  &--application{
    .accordion__item {
      &:first-of-type {
        .accordion__text {
          display: none;
        }
      }
    }
  }
  p {
    font-weight: bold;
    color: #fff;
    margin: 0;
  }

  .accordion__title {
    margin: rem(15) 0;
    position: relative;
    cursor: pointer;

    &::before, &::after {
      content: '';
      position: absolute;
      width: rem(15);
      height: rem(3);
      right: 0;
      top: 50%;
      margin-top: rem(-1.5);
      background-color: $c-primary;
      transition: all .4s ease;
      transform: rotate(0);
    }

    &.active {
      &::before {
        transform: rotate(360deg);
      }

      &::after {
        transform: rotate(450deg);
      }
    }
  }

  .accordion__text {
    padding: rem(20);
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
    display: none;
  }

}

