.contact{
  display: flex;  
  flex-wrap:wrap;
  &-info{
    display: flex;
    flex: 0 0 50%;
    padding-bottom: 35px;
    @include media($md) {
        flex: 0 0 100%;
    }
  }

  &-icon{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3.125rem;
    height: 3.125rem;
    &.icon{
      font-size: 160px;
    }
  }

  &-data{
    padding-left: 8px;
  }

  &-h3{
    margin-top: 0;
    margin-bottom: 8px;
  }

  &-text{
    margin-bottom: 0;
  }

  &-link{
    color: $c-primary;
  }

  &-map{
    padding-top: 40px;
  }
}
.contact-icon{
  .icon{
    width: 30px;
    height: 30px;
  }
}