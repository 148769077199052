.dimensions{
    .col{
        @include media($md){
            flex: 0 0 100%;
            max-width: 100%;
            &.col-7{
                margin-top: rem(40);
            }
        }
    }
}
.dimensions__content{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    position: relative;
    img{
        width: 220px;
        height: 170px;
        object-fit: cover;
        object-position: center;
        @include media(500px){
            width: 150px;
            height: 110px;
        }
    }
}
.dimensions__params{
    position: absolute;
    &--height{
        left: 0;
        top: 50%;
        transform:translate(40px, -30%);
        @include media($md){
            transform:translate(70px, -30%); 
        }
        @include media(700px){
            transform:translate(30px, -30%); 
        }
        @include media($sm){
            transform:translate(0px, -30%); 
        }
        @include media(500px){
            transform:translate(30px, -30%); 
        }
        @include media($xs){
            transform:translate(20px, -30%); 
        }
        @include media(360px){
            transform:translate(0px, -30%); 
        }
    }
    &--width{
        left: 50%;
        top: 10px;
        transform:translate(100px, -100%);
        @include media($lg){
            top: 0;
            transform:translate(100px, -100%);
        }
        @include media(500px){
            transform:translate(100px, -20px);
        }
        @include media($xs){
            transform:translate(80px, -20px);
        }
    }
    &--long{
        left: 50%;
        bottom: 10px;
        transform:translate(80px, 100%);
        @include media($lg){
            bottom: 0;
            transform:translate(100px, 100%);
        }
        @include media(500px){
            transform:translate(100px, 20px);
        }
        @include media($xs){
            transform:translate(80px, 20px);
        }
    }
    @include media($lg){
        display: flex;
        flex-direction: column;
    } 
    @include media($md){
        display: block;
    }
    @include media(500px){
        display: flex;
        flex-direction: column;
    }
}
.dimensions__title{
    font-size: rem(20);
    font-family: $f-heading;
    color: $c-main;
    font-weight: 700;
    line-height: 1.2;  
}
.dimensions__value{
    font-size: rem(16);
    color: $c-main-lighten;
    line-height: 1.5;    
}