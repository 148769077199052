.about__advantages {
  margin: rem(20) 0 rem(45);
  @include small() {
    margin-bottom: rem(15);
  }
}

.about__actions {
  display: flex;
  @include small() {
    flex-direction: column-reverse;
  }

  .btn {
    margin-right: rem(50);
  }

  .phone {
    display: inline-flex;
    align-items: center;
    text-decoration: none;
    @include small() {
      margin-bottom: rem(20);
    }
    .icon {
      font-size: rem(18);
      margin-right: rem(5);
    }
  }
}

.about__gradient-border-block {
  display: grid;
  grid-template: repeat(2, rem(226))/repeat(2,rem(280));
  grid-gap: rem(25);

  @include desktop() {
    grid-gap: rem(20);
    grid-template: repeat(2, rem(226))/repeat(2, calc( 50% - 10px));
  }

  @include laptop() {
    margin-top: rem(30);
  }

 

  .gradient-border-block {
    width: 100%;
    height: 100%;
    &:nth-of-type(even) {
      margin-top: rem(25);
      @include laptop() {
        margin-top: 0;
      }
    }
  }
}