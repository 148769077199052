@import "normalize.css/normalize";
@import "swiper/swiper-bundle";
// functions //
@import "functions/reset";
@import "functions/vars";
@import "functions/mixins";
@import "functions/fonts";
@import "functions/config";
@import "functions/function";
@import "functions/base";
@import "functions/tipography";
@import "functions/grid";

// modules //
@import "modules/buttons";
@import "modules/images-items";
@import "modules/icons-block";
@import "modules/gr-border";
@import "modules/sliders";
@import "modules/modals";
@import "modules/video";
@import "modules/icon-title-list";
@import "modules/steps__list";
@import "modules/image-bg";
@import "modules/forms";
@import "modules/accordion";
@import "modules/burger";
@import "modules/nav";
@import "modules/overlay";
@import "modules/breadcrumbs";
@import "modules/masonry-grid";
@import "modules/masonry-item";
@import "modules/pagination";
@import "modules/column-text";
@import "modules/inputs";
@import "modules/select";

//blocks
@import "blocks/header";
@import "blocks/main-slider";
@import "blocks/about";
@import "blocks/services";
@import "blocks/products";
@import "blocks/steps";
@import "blocks/news";
@import "blocks/fag";
@import "blocks/footer-form";
@import "blocks/footer";
@import "blocks/page-header";
@import "blocks/guarantees";
@import "blocks/portfolio-inner";
@import "blocks/portfolio-inner__page";
@import "blocks/advantages-block";
@import "blocks/about-company";
@import "blocks/contact-page";
@import "blocks/price";
@import "blocks/dimensions";
@import "blocks/application";
@import "blocks/faqPage";
@import "blocks/job";
@import "blocks/promotion";
@import "blocks/quiz";
@import "blocks/color";
@import "blocks/popup-form";
@import "blocks/search";
@import "blocks/top";

@import "media";

