.gradient-border-block {
  background: $gradient;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    left: 2px;
    top: 2px;
    right: 2px;
    bottom: 2px;
    background-color: #fff;
    z-index: 1;
  }
  
  
}

.gradient-border-block__content {
  position: relative;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: rem(15);
  text-align: center;
  .video-block{
    width: 100%;
    .icon{
      margin-bottom: 0;
    }
    @include media($lg){
      position: absolute;
      width: 100%;
      aspect-ratio: auto;
      margin-bottom: 0;
    }
  }
  &--about {
    flex-direction: row;
    width: auto;
    @include media($lg) {
      width: 350px;
    }
  }
  .icon {
    font-size: rem(68);
    margin-bottom: rem(15);
  }

  .title {
    font-family: $f-heading;
    color: $c-primary;
    font-size: rem(48);
    font-weight: bold;
    line-height: 0.5;
    letter-spacing: -0.48px;
    margin-bottom: rem(15);
  }
  &--red{
    background: $c-primary;
    .title{
      color:$c-white;
    }
    *{
      color:$c-white;
      fill:$c-white;
    }
  }
}