.guarantees-section {
  .guarantees-slider {
    .swiper-slide {
      height: auto;
    }
    .slider__content {
      padding: rem(30) rem(20);
      margin: 0;
      background-color: $c-main-bg;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      text-align: center;
      height: 100%;
    }
    .icon--icon-check {
      flex-shrink: 0;
      font-size: rem(30);
      margin-bottom: rem(14);
    }
  }
}