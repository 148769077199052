.top{
    position: fixed;
    width: 60px;
    height: 60px;
    right: 40px;
    bottom: 40px;
    background: $c-primary;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    opacity: 0;
    visibility: hidden;
    transition: $transition-primary;
    z-index: 6;
    cursor: pointer;
    .icon{
        transform: rotate(180deg);
        color: #fff;
        font-size: rem(22);
    }
    &.active{
        opacity: 1;
        visibility: visible;
    }
}