.application{
    .col-8{
        img{
            width: 100%;
            object-fit: cover;
            max-height: 470px;
        }
    }
    .accordion__item{
        *{
            color:$c-main;
            font-weight: 500;
        }
          
    }
    .lists__wrapper{
        padding-bottom: rem(40);
        @include media($lg){
            padding-bottom: rem(15);
        }
    }
    h3{
        font-size: rem(24);
        font-family: $f-heading;
        line-height: 1;
        margin-top: 0;
      }
    .accordion__title{
       a{
           text-decoration: none;
           transition: $transition-primary;
           &:hover{
                color: $c-primary;
           }
       }
       span{
           color: $c-primary;
       }
        &:after{
            content: '';
            display: none;
        }
        &:before {
            display: none;
        }
        &.active {
            &:before {
                transform:translate(10px, -50%) rotate(90deg);
                
            }
        }
    }
    .accordion__text{
        border-top: 0;
        border-bottom: 0;
        font-size: rem(16);
        line-height: 1.125;
        font-weight: 400;
        padding: 0;
    }
    .col{
        @include media($lg){
            flex: 0 0 100%;
            max-width: 100%;;
        }
    }
}
.application__accordion{
    @include media($lg){
        margin-top: rem(30);
    }
}
.application__accordion, .application__form{
    background: $c-main-bg;
    padding: 35px;
}
.application__form{
    display: flex;
    flex-direction: column;
    align-items: center;
    .btn{
        width: 100%;
        max-width: 400px;
        height: rem(54);
    }
    .politic{
        color:$c-main;
        font-size: 15px;
        line-height: 1.125;
        font-weight: 500;
       a{
           color:$c-primary;
          //text-decoration: none;
       } 
    }
    input{
        max-width: 400px;
    }
}
