.pagination {
  display: flex;
  justify-content: center;
  margin-bottom: rem(90);
  @include media($md) {
    margin-bottom: rem(60);
  }
  @include media($sm) {
    margin-bottom: rem(40);
  }
  ul {
    display: flex;
  }
  li {
    border-radius: 50%;
    position: relative;
    overflow: hidden;
    padding: 0;
    &:not(:last-child){
      margin-right: 7px;
    }
    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: $c-search;
      z-index: -1;
      opacity: .3;
      transition: all .3s ease;
    }
    &:hover, &.active {
      &::before {
        opacity: 1;
      }
    }
    &:active {
      transform: translateY(1px);
    }
  }
  .pagination--prev {
    .icon {
      transform: rotate(90deg);
    }
  }
  .pagination--next {
    .icon {
      transform: rotate(-90deg);
    }
  }
  a, span {
    color: $c-main;
    font-size: 16px;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 45px;
    height: 45px;
  }
}